import styled from "styled-components";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { Party } from "../types";
import { RSVPForm } from "../components/rsvp/RSVPForm";
import { medPurple } from "../constants/colors";

export function RSVP({
  rsvpInfo,
  partyId,
}: {
  rsvpInfo: Party;
  partyId: string;
}) {
  return (
    <StyledRSVP id="rsvp" className="pageSetup">
      <div className="pageTitle">
        R S V P |
        <div className="kindlyRespond">
          <div className="kind">Kindly respond by</div>
          <div className="kindDate">06 • 30 • 2024</div>
        </div>
      </div>
      <RSVPForm rsvpInfo={rsvpInfo} partyId={partyId} />
    </StyledRSVP>
  );
}

const StyledRSVP = styled.div`
  width: 80%;

  .pageTitle {
    display: flex;

    .kindlyRespond {
      display: flex;
      align-items: center;

      .kind {
        font-family: "Saturday Night";
        font-size: 0.8em;
        padding-left: 0.3em;
        padding-right: 0.3em;
        color: ${medPurple};
      }

      .kindDate {
        font-size: 0.5em;
      }
    }
  }

  .form {
    padding-top: 5em;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap: 1em;
    width: 50%;
    align-self: center;
    justify-self: center;
  }

  .enter {
    font-family: "Saturday Night";
    font-size: 3em;
    padding-top: 1em;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 80%;

    .pageTitle {
      flex-direction: column;

      .kindlyRespond {
        .kind {
          font-size: 1em;
          padding-right: 0.3em;
          color: ${medPurple};
        }

        .kindDate {
          font-size: 0.6em;
        }
      }
    }
  }
`;
