import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useState } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import { darkPurple, lightPurple } from "../constants/colors";

export const scroll = (link: string) => {
  const section = document.querySelector(`#${link}`);
  section?.scrollIntoView({ behavior: "smooth", block: "start" });
};

export function Menu() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
    };
  return (
    <StyledMenu>
      <Box className="appBarWrapper" sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton className="menuButton" onClick={toggleDrawer(true)}>
              <MenuIcon color="primary" />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{ width: 300, height: "100%" }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{ backgroundColor: darkPurple }}
        >
          <List>
            <ListItem className="imgHome">
              <ListItemButton onClick={() => scroll("home")}>
                <img
                  src="/img/logo-dark.png"
                  alt="logo"
                  style={{ width: 250 }}
                />
              </ListItemButton>
            </ListItem>
            {MENU_ITEMS.map(({ name, link }) => (
              <ListItem key={name}>
                <ListItemButton onClick={() => scroll(link)}>
                  <ListItemText
                    className="menuListText"
                    primaryTypographyProps={{
                      fontFamily: "Glacial Indifference",
                      fontSize: "2.2em",
                      color: lightPurple,
                    }}
                    primary={name}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </StyledMenu>
  );
}

const StyledMenu = styled.div`
  display: flex;
  width: 100%;

  .MuiAppBar-root {
    background: transparent;
    box-shadow: none;
  }

  .MuiListItemText-root {
    font-family: "Glacial Indifference";
  }

  .menuButton {
    align-self: left;
  }

  .imgHome {
    padding: 0;
  }
`;

const MENU_ITEMS: { name: string; link: string }[] = [
  { name: "Getting There", link: "getting-there" },
  { name: "RSVP", link: "rsvp" },
  { name: "FAQ", link: "faq" },
  { name: "Contact Us", link: "contact-us" },
];
