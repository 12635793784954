import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App, { PasswordProtectedComponents } from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { darkPurple, lightPurple, medPurple } from "./constants/colors";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ContactUs } from "./pages/ContactUs";
import { Admin } from "./pages/Admin";
import { Program } from "./pages/Program";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />}>
        <Route
          path="rsvp"
          element={<PasswordProtectedComponents partyIdError="" />}
        />
        <Route path="contact-us" element={<ContactUs />} />
      </Route>
      <Route path="admin" element={<Admin />} />
      <Route path="program" element={<Program />} />
    </>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      main: darkPurple,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontFamily: "Glacial Indifference",
          fontSize: "1em",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "Glacial Indifference",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          fontFamily: "Glacial Indifference",
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          fontFamily: "Glacial Indifference",
        },
      },
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          fontFamily: "Glacial Indifference",
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          backgroundColor: darkPurple,
          color: lightPurple,
          borderRadius: "10px",
          "& button": {
            color: lightPurple,
          },
          "& button:disabled": {
            color: medPurple,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          "& button": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: medPurple,
          color: "white",
          ".MuiAlert-icon": {
            color: "white",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Glacial Indifference",
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
