import { useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { CountdownTimer } from "../components/CountdownTimer";
import { Squiggle } from "../components/Squiggle";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { medPurple } from "../constants/colors";

export function Home() {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);

  const MobileFormat = () => {
    return (
      <>
        <div className="name1">Kshamina</div>
        <img
          src="/img/logo-transparent.png"
          alt="The Shams"
          className="homeLogo"
        />
        <div className="name2">& Arsham</div>
        <Squiggle />
      </>
    );
  };

  return (
    <StyledHome id="home" className="pageSetup">
      <div className="homeLogoContainer">
        {isMobile ? (
          <MobileFormat />
        ) : (
          <>
            <div className="name1">Kshamina & Arsham</div>
            <img
              src="/img/logo-transparent.png"
              alt="The Shams"
              className="homeLogo"
            />
          </>
        )}
        <div className="alignRight">
          <CountdownTimer />
        </div>
      </div>
    </StyledHome>
  );
}

const StyledHome = styled.div`
  .homeLogoContainer {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 80px;
    color: ${medPurple};
    font-family: "Saturday Night";
    width: 80%;
    margin: 0 auto;

    .name1 {
      text-align: left;
      align-self: flex-start;
      padding-left: 1em;
    }

    .alignRight {
      padding-right: 1em;
      align-self: flex-end;
    }
  }

  .homeLogo {
    width: 50vw;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    .homeLogoContainer {
      font-size: 6em;

      .name1 {
        padding-left: 0em;
        align-self: center;
      }

      .alignRight {
        padding-right: 0em;
        align-self: center;
      }
    }

    .homeLogo {
      width: 100vw;
    }
  }
`;
