import styled from "styled-components";
import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Box from "@mui/material/Box";
import { Button, Collapse, useMediaQuery } from "@mui/material";
import { darkPurple, medPurple } from "../constants/colors";

import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";

export function Schedule({
  timelineSteps,
  date,
  title,
  subtitle = "",
}: {
  timelineSteps: TimelineStep[];
  date: string;
  title: string;
  subtitle?: string;
}) {
  const [activeStep, setActiveStep] = useState<number | null>(0);
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);

  const subtitleString = isMobile ? subtitle : ` - ${subtitle}`;

  return (
    <StyledSchedule>
      <div className="pageTitle">
        Schedule |
        <div className="gettingThere">
          <div className="gettingThereDate">{date}</div>
        </div>
      </div>
      <div className="scheduleEventTitle">
        {title}
        {subtitle && (
          <div className="scheduleEventSubtitle">{subtitleString}</div>
        )}
      </div>
      <Timeline className="timeline">
        {timelineSteps.map((step, index) => (
          <TimelineItemComponent
            key={step.activity}
            {...step}
            isActive={activeStep === index}
            handleOnClick={() => {
              setActiveStep((prev: number | null) => {
                if (prev === index) {
                  return null;
                }
                return index;
              });
            }}
          />
        ))}
      </Timeline>
    </StyledSchedule>
  );
}

const TimelineItemComponent = ({
  time,
  activity,
  details,
  isActive,
  handleOnClick,
}: TimelineStep & { isActive: boolean; handleOnClick: () => void }) => {
  return (
    <Button
      disableRipple
      onClick={handleOnClick}
      className={isActive ? "active" : "inactive"}
    >
      <TimelineItem>
        <TimelineOppositeContent className="time">
          {time}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={isActive ? "secondary" : "primary"} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="activity">{activity}</div>
          <Box sx={{ display: "flex" }}>
            <Collapse in={isActive}>{<>{details}</>}</Collapse>
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Button>
  );
};

export type TimelineStep = {
  time: string;
  activity: string;
  details: React.ReactNode | string;
};

const StyledSchedule = styled.div`
  width: 80%;
  margin: 0 auto;

  a {
    color: ${darkPurple};
    padding: 0 5px; 
  }

  .subtitle {
    font-family: "Saturday Night";
    font-size: 3em;
  }
  

  .pageTitle {
    display: flex;

    .gettingThere {
      display: flex;
      align-items: center;

      .gettingThereDate {
        font-size: 0.7em;
        padding-left: 20px;
      }
    }
  }

  .shiftLeft {
    margin-left: 1em;
  }

  .scheduleEventTitle {
    font-family: "Saturday Night";
    font-size: 3.5em;
    margin-left: 3em; 

    display: flex; 
    align-items: center; 

    .scheduleEventSubtitle {
      font-size: 0.8em;
      color: ${darkPurple}; 
      padding-left: 10px; 
    }
  }



  .MuiTimelineContent-root {
    min-width: 70%;
  }

  .MuiTimelineItem-root {
    width: 80%: 
  }

  button {
    text-transform: none;

    // fixes weird indent
    display: block !important;

    &.active {
      color: ${medPurple};
    }
  }

  button:hover {
    color: ${medPurple};
  }

  .time {
    font-family: Genty;
    font-size: 2.2em;
  }

  .activity {
    font-family: Saturday Night;
    font-size: 2.8em;
  }

  button:hover {
    background-color: transparent;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100% !important;

    .pageTitle {
      margin-left: 1em;
      .gettingThere {
        .gettingThereDate {
          padding-left: 10px;
        }
      }
    }

    .subtitle {
      font-size: 3em;
    }


    .scheduleEventTitle {
      font-size: 2.5em;
      margin-left: 0em; 
      text-align: center; 

      display: flex; 
      flex-direction: column;

      .scheduleEventSubtitle {
        font-size: 0.8em;
        padding: 0px 1em; 
      }
    }

    .MuiTimelineContent-root {
      min-width: 65%;
    }

    .time {
      font-size: 1.2em;
    }

    .activity {
      font-size: 2em;
    }
  }
`;
