import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  MobileStepper,
  Modal,
  StepButton,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "styled-components";
import { Party } from "../../types";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { BREAKPOINT_MOBILE } from "../../constants/breakpoints";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { darkPurple, medPurple } from "../../constants/colors";
import { updateRsvp } from "../../requestUtils/request";
import { FinalStep } from "./FinalStep";
import { RsvpPerPerson } from "./RsvpPerPerson";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import { GuestNameInput, isMissingGuestName } from "./GuestName";

export function RSVPForm({
  rsvpInfo,
  partyId,
}: {
  rsvpInfo: Party;
  partyId: string;
}) {
  const guestInfo = rsvpInfo.guests;
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState<React.ReactNode | string>("");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: isMobile ? 2 : 6,
    color: darkPurple,
    fontSize: isMobile ? 20 : 24,
    fontFamily: "Glacial Indifference",
    borderRadius: "50px",
    boxShadow: `0 0 0 2px ${medPurple}, -10px 10px 0px 0px ${medPurple}`,
  };

  const [formState, setFormState] = useState<FormState[]>(
    guestInfo.map((guest) => ({
      id: guest.guestId,
      name: guest.guestName,
      mehendi: guest.mehendhi.isAttending,
      ceremony: guest.ceremony.isAttending,
      reception: guest.reception.isAttending,
      dietary: guest.dietaryRestrictions,
      comments: guest.Other,
    }))
  );
  const [activeStep, setActiveStep] = useState<number>(0);

  const totalSteps = () => {
    return guestInfo.length;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  async function handleSubmit() {
    const response = await updateRsvp(partyId, formState);
    setModalOpen(true);
    if (response) {
      setModalText("You have successfully RSVP'd!");
    } else {
      setModalText(
        <>
          There was an issue with your RSVP. Please try again or email us at{" "}
          <a href="mailto:arkshamina@gmail.com">arkshamina@gmail.com</a>
        </>
      );
    }
  }

  function setFormStateAtIndex({
    index = activeStep,
    key,
    newValue,
  }: {
    index?: number;
    key: keyof FormState;
    newValue: boolean | string;
  }) {
    const form = formState[index];
    setFormState((prev: FormState[]) => {
      const newFormState = [...prev];
      newFormState[index] = { ...form, [key]: newValue };
      return newFormState;
    });
  }

  const MobileStepContent = () => {
    return activeStep >= guestInfo.length ? (
      <FinalStep
        formState={formState}
        setFormState={setFormStateAtIndex}
        activeStep={activeStep}
        handleSubmit={handleSubmit}
        handleBack={handleBack}
        totalSteps={totalSteps()}
      />
    ) : (
      <div className="stepContentContainer">
        <div className="stepContentMobile">
          <div className="mobileGuestName">
            {guestInfo[activeStep]?.guestName ? (
              <div className="mobileGuestNameBox">
                rsvp for:{" "}
                {isMissingGuestName(guestInfo[activeStep]?.guestName) ? (
                  <GuestNameInput
                    formState={formState[activeStep]}
                    setFormState={setFormStateAtIndex}
                    index={activeStep}
                  />
                ) : (
                  <div className="guestName">
                    {guestInfo[activeStep]?.guestName}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <RsvpPerPerson
            formState={formState[activeStep]}
            guestInfo={guestInfo[activeStep]}
            setFormState={setFormStateAtIndex}
          />
        </div>
      </div>
    );
  };
  const StepContent = () => {
    return activeStep >= guestInfo.length ? (
      <FinalStep
        formState={formState}
        setFormState={setFormStateAtIndex}
        activeStep={activeStep}
        handleSubmit={handleSubmit}
        handleBack={handleBack}
        totalSteps={totalSteps()}
      />
    ) : (
      <div className="stepContentContainer">
        <div className="stepContent">
          <RsvpPerPerson
            formState={formState[activeStep]}
            guestInfo={guestInfo[activeStep]}
            setFormState={setFormStateAtIndex}
          />
          <div className="buttonsStep">
            <Button
              className="backButton"
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep <= 0}
            >
              Back
            </Button>
            <Button
              className="nextButton"
              variant="outlined"
              onClick={handleNext}
            >
              {activeStep < guestInfo.length && "Next"}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const AlreadySubmitted = () => {
    return (
      <Alert
        className="alreadySubmitAlert"
        icon={<CheckIcon fontSize="inherit" />}
        severity="success"
      >
        You have already submitted your RSVP! Feel free to update and re-submit
        up until June 30th!
      </Alert>
    );
  };

  return (
    <RSVPFormStyles hasResponded={rsvpInfo.hasResponded}>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className="submitModal" sx={style}>
          <CardContent>{modalText}</CardContent>
          <CardActions>
            <Button onClick={() => setModalOpen(false)} size="small">
              Close
            </Button>
          </CardActions>
        </Card>
      </Modal>
      {isMobile ? (
        <>
          <Box
            sx={{
              height: rsvpInfo.hasResponded ? "78vh" : "75vh",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {rsvpInfo.hasResponded && <AlreadySubmitted />}
            <MobileStepContent />
          </Box>
          <MobileStepper
            variant="text"
            steps={totalSteps() + 1}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === totalSteps()}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </>
      ) : (
        <React.Fragment>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {guestInfo.map(({ guestId, guestName }, index) => {
              return (
                <Step key={guestId}>
                  {isMissingGuestName(guestName) ? (
                    <StepButton
                      disableRipple
                      color="inherit"
                      onClick={handleStep(index)}
                    >
                      <GuestNameInput
                        formState={formState[index]}
                        setFormState={setFormStateAtIndex}
                        index={index}
                      />
                    </StepButton>
                  ) : (
                    <StepButton
                      disableRipple
                      color="inherit"
                      onClick={handleStep(index)}
                    >
                      {guestName}
                    </StepButton>
                  )}
                </Step>
              );
            })}
            <Step>
              <StepButton
                color="inherit"
                onClick={handleStep(guestInfo.length)}
              >
                Submit
              </StepButton>
            </Step>
          </Stepper>
          {rsvpInfo.hasResponded && <AlreadySubmitted />}
          <StepContent />
        </React.Fragment>
      )}
    </RSVPFormStyles>
  );
}

export type FormState = {
  id: string;
  name: string;
  mehendi: boolean;
  ceremony: boolean;
  reception: boolean;
  dietary: string;
  comments: string;
};

const RSVPFormStyles = styled.div<{ hasResponded: boolean }>`
  padding-top: 2em;

  .stepContentContainer {
    margin: 2em auto;
    width: 80%;
    background-color: white;

    border-radius: 50px;
    box-shadow: 0 0 0 2px ${medPurple}, -10px 10px 0px 0px ${medPurple};

    min-height: 30vh;

    .stepContent {
      display: grid;
      grid-template-rows: auto auto auto;
      grid-template-columns: auto;
      padding: 2.5em;

      .buttonsStep {
        display: flex;
        justify-content: space-between;
        margin-top: 2em;

        button {
          width: 5em;
        }
      }

      .MuiFormControlLabel-label {
        font-family: "Glacial Indifference";
        text-transform: uppercase;
        font-size: 0.9em;
        color: ${darkPurple};
      }

      .MuiInputLabel-root,
      .MuiInputBase-root {
        font-family: "Glacial Indifference";
      }
    }
  }

  .MuiStepLabel-alternativeLabel,
  .MuiTextField-root,
  .guestNameInput > .MuiInputBase-root,
  .guestNameInput > .MuiInputBase-root > input {
    font-family: "Glacial Indifference";
    padding: 0 !important;
    font-size: 16px;
  }

  .alreadySubmitAlert {
    font-family: "Glacial Indifference";
    width: 80%;
    margin: 2em auto;
    font-size: 1em;
  }

  .eventRsvp {
    padding: 0.5em 1em;
  }

  .diet {
    width: 80%;
    margin-left: 1em;
    margin-top: 1em;
  }

  .radioGroupLabel {
    font-family: "Glacial Indifference";
    font-size: 1.2em;
  }

  .mobileFinalStep {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;

    .mobileSubmitRsvp {
      justify-self: center;
      margin-top: 1em;
    }
  }

  .submitModal {
    background-color: ${darkPurple};
    color: "white";
    width: 400px;
    font-family: "Glacial Indifference";
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-top: 0.5em;

    .mobileGuestName {
      font-size: 1.9em;
      color: ${darkPurple};
      font-family: "Saturday Night";

      .mobileGuestNameBox {
        display: flex;
        justify-content: center;
        align-items: center;

        .guestName {
          padding-left: 10px;
          font-family: "Glacial Indifference";
          font-size: 0.6em;
        }
      }
    }

    .diet {
      width: 90%;
      margin-left: 0em;
    }

    .stepContentContainer {
      margin: 10px auto;
      width: 100%;

      .stepContentMobile {
        display: flex;
        flex-direction: column;
        padding: 1.5em;
        height: ${(props) => (props.hasResponded ? "65" : "70")}vh;
      }

      .MuiFormControlLabel-label,
      .MuiFormControlLabel-root {
        font-family: "Glacial Indifference";
        text-transform: uppercase;
        font-size: 0.9em;
        color: ${darkPurple};
        height: 30px;
      }
    }

    .guestNameInput {
      padding-left: 10px;
    }

    .mobileFinalStep {
      width: 15em;
      justify-content: center;
      margin-top: 2em;

      .mobileSubmitRsvp {
        justify-self: center;
        margin-top: 1em;
      }
    }

    .radioGroupLabel {
      font-size: 1em;
    }

    .eventRsvp {
      padding: 8px 0;
    }

    .textFieldsRsvp {
      display: grid;
      grid-template-rows: auto;
      grid-row-gap: 1.2em;
    }

    .rsvpPerPerson {
      padding-top: 1em;
      display: grid;
      grid-template-columns: auto;
    }

    .alreadySubmitAlert {
      width: 100%;
      margin: 0;

      .MuiAlert-message {
        padding: 2px 0;
      }
    }

    .submitModal {
      width: 95%;
    }
  }
`;
