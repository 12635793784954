import { TimelineStep } from "../pages/Schedule";

export const MEHENDI_TIMELINE_STEPS: TimelineStep[] = [
  {
    time: "3:00pm",
    activity: "The party begins!",
    details: (
      <div>
        The mehendi party will take place at
        <a href="https://maps.app.goo.gl/oRdbCcbPUkArKb7b6">10 Coulson Court</a>
        in Markham. There will be a henna artist, snacks, light dinner, games
        and more! Stay tuned for more details.
      </div>
    ),
  },
];

export const TIMELINE_STEPS: TimelineStep[] = [
  {
    time: "4:00pm",
    activity: "Arrive at the Henley Room",
    details:
      "Guests may arrive to the venue starting at 4:00pm. You will have some time to mingle and find your seats. The ceremony will start promptly at 4:30pm.",
  },
  {
    time: "4:30pm",
    activity: "Ceremony",
    details:
      "The ceremony will start promptly at 4:30pm so please try to arrive a bit early to mingle and find your seats. The ceremony will be outside (weather permitting) so please dress accordingly.",
  },
  {
    time: "5:15pm",
    activity: "Cocktails",
    details:
      "There will be a little cocktail hour with some snacks and drinks.",
  },
  {
    time: "6:30pm",
    activity: "Dinner",
    details:
      "Dinner will be served at 6:30pm. There will be a few speeches and some dancing. We hope you enjoy the food and the company!",
  },
  {
    time: "12:30am",
    activity: "Closing Time",
    details:
      "Time for you all to go home and rest after a long day of celebrating!",
  },
];
