import { styled } from "styled-components";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { medPurple } from "../constants/colors";
import { Squiggle } from "./Squiggle";

export function Definition() {
  return (
    <StyledDefinition>
      <div className="noun">noun</div>
      <Squiggle width={600} />
      <div className="union">the union of two compatible shams</div>
    </StyledDefinition>
  );
}

const StyledDefinition = styled.div`
  width: 100%;
  font-family: Saturday Night;

  height: 10.82rem;
  display: inline-block;
  flex-direction: column;

  .noun {
    font-size: 2em;
    color: ${medPurple};
  }

  .union {
    font-size: 3em;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    .noun {
      font-size: 3em;
      text-align: left;
    }

    .union {
      font-size: 2.5em;
    }
  }
`;
