import SendIcon from "@mui/icons-material/Send";
import { Button, TextField, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { BREAKPOINT_MOBILE } from "../../constants/breakpoints";
import { FormState } from "./RSVPForm";

export function FinalStep({
  formState,
  setFormState,
  activeStep,
  handleSubmit,
  handleBack,
  totalSteps,
}: {
  formState: FormState[];
  setFormState: any;
  activeStep: number;
  handleSubmit: any;
  handleBack: any;
  totalSteps: number;
}) {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);
  const [comments, setComments] = useState(formState[totalSteps - 1].comments);

  const handleBlur = () => {
    setFormState({
      index: totalSteps - 1,
      key: "comments",
      newValue: comments,
    });
    console.log(formState);
  };

  return (
    <div className="stepContentContainer">
      <div className={`stepContent${isMobile ? "Mobile" : ""}`}>
        <div className="mobileFinalStep">
          <TextField
            variant="outlined"
            label="Any other comments/questions?"
            value={comments}
            multiline={true}
            minRows={4}
            maxRows={4}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComments(event.target.value);
            }}
            onBlur={handleBlur}
          />
          <Button
            variant="contained"
            className="mobileSubmitRsvp"
            onClick={handleSubmit}
            endIcon={<SendIcon />}
          >
            Submit RSVP
          </Button>
        </div>
        {!isMobile && (
          <div className="buttonsStep">
            <Button
              className="backButton"
              variant="outlined"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
