import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, useMediaQuery } from "@mui/material";
import { BREAKPOINT_MOBILE } from "../../constants/breakpoints";
import { Event } from "../../types";
import { FormState } from "./RSVPForm";

export function EventRsvp({
  event,
  eventName,
  eventKey,
  formState,
  setFormState,
}: {
  event: Event;
  eventName: string;
  eventKey: keyof FormState;
  formState: FormState;
  setFormState: ({
    key,
    newValue,
  }: {
    key: keyof FormState;
    newValue: boolean;
  }) => void;
}) {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);

  if (!event.isInvited) {
    return null;
  }

  const isAttending = formState[eventKey];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      key: eventKey,
      newValue: (event.target as HTMLInputElement).value === "yes",
    });
  };

  return (
    <FormControl className="eventRsvp">
      <FormLabel className="radioGroupLabel">{eventName}</FormLabel>
      <RadioGroup
        row={isMobile}
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={isAttending ? "yes" : "no"}
        onChange={handleChange}
      >
        <FormControlLabel
          value={"yes"}
          control={<Radio />}
          label="Joyfully accept"
        />
        <FormControlLabel
          value={"no"}
          control={<Radio />}
          label="Regretfully decline "
        />
      </RadioGroup>
    </FormControl>
  );
}
