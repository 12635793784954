import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { styled } from "styled-components";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { lightPurple } from "../constants/colors";
import { useSearchParams } from "react-router-dom";
import { scroll } from "../components/Menu";

export function NoPartyId({ partyIdError }: { partyIdError: string }) {
  const [partyId, setPartyId] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  // eslint-disable-next-line
  let [_, setSearchParams] = useSearchParams();

  function handleSubmit(event: any) {
    setHasSubmitted(true);
    event.preventDefault();
    let params = { partyId };
    localStorage.setItem("party-id", partyId);
    setSearchParams(params);
    scroll("rsvp");
  }

  return (
    <StyledParty>
      <div className="enter">
        Please submit your party id to enter the website
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          id="outlined-basic"
          label="Party ID"
          variant="outlined"
          value={partyId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPartyId(event.target.value);
          }}
          error={hasSubmitted && partyIdError !== ""}
          {...(hasSubmitted && { helperText: partyIdError })}
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={partyId === ""}
        >
          Submit
        </Button>
      </form>
    </StyledParty>
  );
}

const StyledParty = styled.div`
  background-color: ${lightPurple};
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1em 0;

  form {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap: 1em;
    width: 50%;
    align-self: center;
    justify-self: center;s
  }

  .enter {
    font-family: "Saturday Night";
    font-size: 3em;
    padding: 2em;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 1em 0em;

    form {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      grid-row-gap: 1em;
      width: 80%;
    }

    .enter {
      font-size: 2.2em;
    }
  }
`;
