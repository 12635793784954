import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "styled-components";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { darkPurple } from "../constants/colors";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import DeckIcon from "@mui/icons-material/Deck";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LiquorIcon from "@mui/icons-material/Liquor";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
export function FAQ() {
  return (
    <StyledFAQ id="faq">
      <div className="pageTitle">F A Q</div>
      <div className="faqGrid">
        {QUESTIONS.map((qAndA, index) => {
          return <QandACard key={index} {...qAndA} />;
        })}
      </div>
    </StyledFAQ>
  );
}

function QandACard({
  question,
  answer,
  icon,
}: {
  question: string;
  answer: string | React.ReactNode;
  icon: any;
}) {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);

  return (
    <Card
      sx={{
        ...(isMobile && { minHeight: 200 }),
        border: `1px solid ${darkPurple}`,
        color: darkPurple,
      }}
    >
      <CardActionArea disableRipple>
        {/* <CardMedia
          component="img"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
        /> */}
        <CardContent>
          <div className="question">
            <div>{icon}</div>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ color: darkPurple }}
            >
              {question}
            </Typography>
          </div>
          <Typography className="answer" variant="body1">
            {answer}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const QUESTIONS = [
  {
    icon: <DateRangeIcon />,
    question: "When do I need to RSVP by?",
    answer: (
      <>
        Please RSVP by June 30th. You should have received a Party Id to enter{" "}
        <a href="#rsvp">the RSVP section</a> for scheduling and RSVP details. We
        can't wait to celebrate with you!
      </>
    ),
  },
  {
    icon: <AccessTimeIcon />,
    question: "What time should I arrive for the ceremony?",
    answer:
      "Doors will open for guests at 4pm. We recommend that you arrive between 4 - 4:15pm to have some time before the ceremony starts at 4:30pm.",
  },
  {
    icon: <DeckIcon />,
    question: "Will the events be indoor or outdoor?",
    answer:
      "The wedding ceremony will be outdoors, with an indoor reception to follow. There isn't much grass to walk through so don't worry about your heels getting stuck!",
  },
  {
    icon: <CheckroomIcon />,
    question: "What kind of attire should I wear?",
    answer:
      "The wedding will be an Indian ceremony. You have the option to wear Indian or semi-formal attire!",
  },
  {
    icon: <LocalParkingIcon />,
    question: "Where can I park?",
    answer: (
      <>
        There will be free parking at the venue. For more details, please
        reference <a href="#getting-there">the Getting There section.</a>
      </>
    ),
  },
  {
    icon: <RestaurantIcon />,
    question: "What type of food will you be serving?",
    answer:
      "Dinner during the reception will be an Indian buffet! We will be keeping the spices level low and you can add your dietary restrictions when you RSVP. We will work with our caterer to ensure there are options available for everyone!",
  },
  {
    icon: <LiquorIcon />,
    question: "Will you be serving alcohol?",
    answer:
      "Yes! We will be having an open bar that will open after the ceremony.",
  },
  {
    icon: <CardGiftcardIcon />,
    question: "How can I send a gift?",
    answer:
      "We are so thankful that you will be joining us on our big day and your presence is more than enough! For those who would still like to send us a gift, we would be grateful for any contributions towards our home fund ♡",
  },
  {
    icon: <HelpOutlineIcon />,
    question: "I have more questions!",
    answer: (
      <>
        Please don't hesitate reach out using the methods listed in{" "}
        <a href="#contact-us">the Contact Us section</a>!
      </>
    ),
  },
];

const StyledFAQ = styled.div`
  width: 80%;
  margin: 0 auto;

  .faqGrid {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }

  .question {
    display: grid;
    grid-template-columns: 10% auto;
    align-items: center;
  }

  .answer {
    a {
      /* padding: 0; */
      /* text-decoration: underline; */
      /* min-width: 0; */
      /* padding: 0 5px; */
      /* text-transform: none; */
      color: ${darkPurple};
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 90%;
    .pageTitle {
      margin-left: 1em;
    }

    .faqGrid {
      grid-template-columns: auto;
    }
  }
`;
