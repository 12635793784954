import axios from "axios";
import { FormState } from "../components/rsvp/RSVPForm";
import { GuestParty } from "../pages/Admin";
import { Party } from "../types";

const URL = "https://qazpdjw267.execute-api.us-east-2.amazonaws.com/Prod";
export const GUEST_UPDATE_ENDPOINT = "/guest-update";
export const GET_GUEST_ENDPOINT = "/guest-get";

export async function getGuestsRequest(key?: string): Promise<Party | null> {
  if (!key) {
    return null;
  }

  console.log(key);

  try {
    const url = URL + GUEST_UPDATE_ENDPOINT;
    const body = {
      operation: "list",
      payload: key,
    };
    const response = await axios.post(url, body);
    console.log("WOWOWOWOWOW", response);

    // @ts-ignore
    const party = response.data.Items.find((party: any) => {
      return party.partyId === key;
    });
    return party;
  } catch (error) {
    // Handle error here
    console.log(error);
  }
  return null;
}
export async function getAllGuestsForAdmin(): Promise<GuestParty[] | null> {
  try {
    const url = URL + GUEST_UPDATE_ENDPOINT;
    const body = {
      operation: "list",
    };
    const response = await axios.post(url, body);

    let guestParty: GuestParty[] = [];

    response.data.Items.forEach((party: Party) => {
      const partyId = party.partyId;
      const hasResponded = party.hasResponded;
      return party.guests.forEach((guest) => {
        guestParty.push({
          ...guest,
          mehendiIsInvited: guest.mehendhi.isInvited,
          mehendiIsAttending: guest.mehendhi.isAttending,
          ceremonyIsAttending: guest.ceremony.isAttending,
          receptionIsAttending: guest.reception.isAttending,
          partyId,
          hasResponded,
        });
      });
    });
    return guestParty;
  } catch (error) {
    // Handle error here
    console.log(error);
  }
  return null;
}

export async function updateRsvp(
  partyId: string,
  formState: FormState[]
): Promise<boolean> {
  const responses: boolean[] = [];

  for (const guest of formState) {
    const body = {
      operation: "update",
      guestId: guest.id,
      partyId: partyId,
      isAttendingCeremony: guest.ceremony,
      isAttendingMehendhi: guest.mehendi,
      isAttendingReception: guest.reception,
      other: guest.comments ?? "",
      dietaryRestrictions: guest.dietary ?? "",
      guestName: guest.name ?? "",
      hasResponded: true,
    };

    try {
      const url = URL + GUEST_UPDATE_ENDPOINT;
      await axios.post(url, body);
      responses.push(true);
    } catch (error) {
      // Handle error here
      console.log(error);
      responses.push(false);
    }
  }
  return responses.every((r) => r);
}
