import { styled } from "styled-components";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { medPurple } from "../constants/colors";

export function Squiggle({ width }: { width?: number }) {
  return (
    <Styled width={width ?? 800}>
      <div className="wave"></div>
    </Styled>
  );
}

const Styled = styled.div<{ width: number }>`
  .wave {
    --c: ${medPurple}; /* Color */
    --t: 2px; /* Thickness */
    --h: 20px; /* Height (vertical distance between two curve) */
    --w: 50px; /* Width  */
    --p: 6px; /* adjust this to correct the position when changing the other values*/

    background: radial-gradient(
        farthest-side at 50% calc(100% + var(--p)),
        transparent 47%,
        var(--c) 50% calc(50% + var(--t)),
        transparent calc(52% + var(--t))
      ),
      radial-gradient(
        farthest-side at 50% calc(0% - var(--p)),
        transparent 47%,
        var(--c) 50% calc(50% + var(--t)),
        transparent calc(52% + var(--t))
      );

    background-size: var(--w) var(--h);
    background-position: calc(var(--w) / 2) calc(var(--h) / 2),
      0px calc(var(--h) / 2);

    margin: 10px 0;
    width: ${(props) => props.width}px;
    height: 20px;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    .wave {
      --t: 2px; /* Thickness */
      --h: 16px; /* Height (vertical distance between two curve) */
      --w: 45px; /* Width  */
      --p: 5px; /* adjust this to correct the position when changing the other values*/

      width: 300px;
      height: 16px;
    }
  }
`;
