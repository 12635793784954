import { Button } from "@mui/material";
import styled from "styled-components";
import { Definition } from "../components/Definition";
import { Squiggle } from "../components/Squiggle";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { darkPurple, medPurple } from "../constants/colors";

export function Home2() {
  return (
    <StyledHome>
      <div className="grid">
        <img
          src="/img/engagement.jpg"
          className="engagement"
          alt="engagement"
        />
        <div className="welcomeBlock">
          <div className="welcomeInner">
            <div className="welcome">
              <div>Welcome to the wedding website of</div>
            </div>
            <div className="shams">The Shams*</div>

            <div className="animation">
              <div className="dateTimeTest">
                <Squiggle width={600} />
                <div className="date">08 • 18 • 2024</div>
                <div className="city">Toronto, Ontario</div>
              </div>
              <Definition />
            </div>
            <Button
              className="rsvpHere"
              variant="outlined"
              onClick={() => document.getElementById("rsvp")?.scrollIntoView()}
            >
              RSVP Here
            </Button>
          </div>
        </div>
      </div>
    </StyledHome>
  );
}

const StyledHome = styled.div`
  .grid {
    display: grid;
    grid-template-columns: 45vw 40vw;
    grid-template-rows: auto auto;
    padding-top: 5%; 
    margin: 0 auto;
    
  }
  
  .welcomeBlock {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .welcome {
    font-family: Saturday Night;
    font-size: 3.5em;
    color: ${medPurple};
  }

  .shams {
    font-family: Genty;
    font-size: 7em;
    color: ${darkPurple};
    scroll-snap-align: start;
  }

  .date {
    font-family: Genty;
    font-size: 45px;
    color: ${darkPurple};
  }

  .city {
    font-family: "Saturday Night";
    font-size: 55px;
    color: ${medPurple};
  }

  .image {
    position: relative: 
    grid-row: span 2; 
  }
  
  .engagement {
    border-radius: 50px;
    width: 24vw;
    height: auto;
    box-shadow: 0 0 0 2px ${medPurple}, -10px 10px 0px 0px ${medPurple};

    animation-name: flo;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    margin-top: 1em; 
  } 

  @keyframes flo{
    50%{
      transform: rotate(-5deg);
    }
  }

  .dateTimeTest {
      animation: text-animation 12s infinite;
    }

  .animation {
    height:12rem;
    overflow:hidden;
  }

  .dateTimeTest {
    flex-direction: column; 
    height:10.82rem;
    margin-bottom: 1.82rem;
    overflow:hidden;
    display:inline-block;
  }
    

  @keyframes text-animation {
  0% {
      margin-top: 0;
  }
  8.33%, 25%, 33.33%, 50% {
    margin-top: -42.64rem;
  }
  58.33%, 75% {
    margin-top: 0;
  }
  83.33%, 100% {
    margin-top: 0;
  }
}

  button {
    margin-top: 2em; 
    font-size: 1.2em;
    width: 10em;

  }

@media (max-width: ${BREAKPOINT_MOBILE}px) {
  width: 85%; 
  margin: 0 auto;

  .grid {
    grid-template-columns: auto;
    grid-template-rows: auto auto;   
    text-align: center;  
  }
  
  .welcome {
    font-size: 3em;
  }

  .shams {
    font-size: 4em;
  }

  .date {
    font-size: 25px;
  }

  .city {
    font-size: 45px;
  }

  .image {
    grid-row: span 1; 
  }
  
  .engagement {
    width: auto;
    height: 32vh;
    box-shadow: 0 0 0 1px ${medPurple}, -5px 5px 0px 0px ${medPurple};
    margin: 0em 0 1em; 
  } 


  .animation {
    height: 12rem;
  }

  .dateTimeTest {
    height:10.82rem;
    margin-bottom: 1.82rem;
  }

  button {
    margin-top: -1em; 
  }
}
`;
