import React from "react";
import { styled } from "styled-components";
import { darkPurple, lightPurple } from "../constants/colors";

export function Program() {
  function ProgramItem({
    title,
    titleEng,
    description,
    alt,
    src,
  }: {
    title: string;
    titleEng: string;
    description: string[];
    alt: string;
    src: string;
  }) {
    return (
      <div className="programItem">
        <div className="programTitleContainer">
          <div>
            <div className="programItemTitle">{title}</div>
            <div className="programItemTitleEng">{titleEng}</div>
          </div>
          <img className="programImg" alt={alt} src={src} />
        </div>
        <div className="programItemDescription">
          {description.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
      </div>
    );
  }

  return (
    <StyledProgram>
      <div className="container">
        <div className="pageTitle">
          <div className="title">Kshamina & Arsham's Ceremony</div>
          <div className="date">August 18th, 2024 - 4:30pm</div>
          <img
            src="/img/logo-transparent.png"
            alt="The Shams"
            className="programLogo"
          />
        </div>
        <div className="programItem mainTitle">
          <div className="programItemTitle">Hindu Vivaah Sanskar</div>
          <div className="programItemTitleEng">Hindu Wedding Ceremony</div>
          <div className="programItemDescription">
            <p>
              Hindu Vivaah is a religious ceremony and one of the most important
              of the sixteen Hindu sansakars (sacraments). The Hindu service is
              performed by a priest in accordance with the mantras (holy verses
              from the Vedas — the Hindu Holy books). A Hindu wedding is seen
              not only as a bond between two, but also as a bond between
              families for generations to come.
            </p>
            <p>
              The ceremony will be performed by Pankajbhai Shukla. Fun fact, he
              also performed the Bride's parent's wedding back in 1990!
            </p>
          </div>
        </div>
        <ProgramItem
          title="Jaan Swagat and Milan"
          titleEng="Welcoming the Groom's Party"
          description={[
            "The Jaan is a procession in which the Groom and his parents, Mitra Haghighi-Fard and Manochehr Jalayer, arrive to the wedding.",
            "The bride's parents, Daxa and Vijay Ghelani, greet the arriving Jaan at the front of the main aisle. Both families exchange greetings to celebrate the 'Milan' – the meeting of the two families. Daxa then places a Chandlo on Arsham and gives herblessings. After receiving the blessings, Arsham proceeds to the Mandap.",
          ]}
          alt="milni"
          src="/img/program/milni.png"
        />
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Kanya Pravesh</div>
              <div className="programItemTitleEng">Entrance of the Bride</div>
            </div>
            <img
              className="programImg"
              alt="entrance"
              src="/img/program/ksham.jpg"
            />
          </div>
          <div className="programItemDescription">
            Kshamina is escorted by her Mom and Dad to the Mandap.
          </div>
        </div>
        <ProgramItem
          title="Ganesh Pooja"
          titleEng="Worship of Lord Ganesh"
          description={[
            "Prior to Kshamina's arrival, blessings are invoked from Lord Ganesh. By seeking the blessings of Lord Ganesh, we pray that all obstacles are removed from the ceremony and that Kshamina and Arsham's marriage ceremony is completed without hindrances.",
          ]}
          alt="ganesh"
          src="/img/program/ganesha.png"
        />
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Jaimala</div>
              <div className="programItemTitleEng">
                Exchanging Flower Garlands
              </div>
            </div>
            <img
              className="programImg"
              alt="jaimala"
              src="/img/program/garland.png"
            />
          </div>
          <div className="programItemDescription">
            By exchanging Jaimalas (garlands), Kshamina and Arsham declare that
            they accept each other as their life partner and wish to commence
            with the sacrament of marriage.
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Hasta Melap</div>
              <div className="programItemTitleEng">
                Giving Daughter's Hand in Marriage
              </div>
            </div>
            <img
              className="programImg"
              alt="hands"
              src="/img/program/kanyadaan.png"
            />
          </div>

          <div className="programItemDescription">
            In this poignant part of the ceremony, Kshamina's parents offer her
            hand in marriage to Arsham. They request Arsham to accept their
            daughter as an equal partner in all walks of life. Arsham promises
            to accept Kshamina as his equal life partner.
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Ganth Bandhan</div>
              <div className="programItemTitleEng">Tying the Knot</div>
            </div>
            <img
              className="programImg"
              alt="knot"
              src="/img/program/knot.png"
            />
          </div>
          <div className="programItemDescription">
            The Chundris (scarves) placed around Kshamina and Arsham, are tied
            together by the Maharaj. The tying of these scarves symbolizes their
            eternal bond. With this union, they pledge before all to love each
            other and remain faithful to one another.
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Havan</div>
              <div className="programItemTitleEng">Sacred Fire</div>
            </div>
            <img
              className="programImg"
              alt="agni"
              src="/img/program/agni.png"
            />
          </div>
          <div className="programItemDescription">
            A fire lit in the centre of the Mandap invites Lord Agni (Fire) to
            witness Kshamina and Arsham's union. Their palms are filled with
            rice, oats, and leaves to signify health, wealth, happiness, and
            prosperity. These are then offered into the fire.
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Mangal Phera</div>
              <div className="programItemTitleEng">
                Walking Around the Sacred Fire
              </div>
            </div>
            <img
              className="programImg"
              alt="steps"
              src="/img/program/bonfire.png"
            />
          </div>

          <div className="programItemDescription">
            Kshamina and Arsham walk around the Havan (Sacred Fire). After
            completing each round, they stop to touch a stone in their path.
            This is symbolic of obstacles in life that they will overcome
            together. These four rounds stand for the four basic human goals:
            <ul>
              <ol>1. Dharma: Religious & Moral Duties</ol>
              <ol>2. Artha: Good fortune and Prosperity</ol>
              <ol>3. Kama: Desires & Passion of Life</ol>
              <ol>4. Moksha: Spiritual Salvation & Liberation</ol>
            </ul>
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Saptpadi</div>
              <div className="programItemTitleEng">
                Taking Their First Seven Steps Together
              </div>
            </div>
            <img
              className="programImg"
              alt="steps2"
              src="/img/program/footprints.png"
            />
          </div>

          <div className="programItemDescription">
            <p>
              Kshamina and Arsham take seven steps together symbolizing, the
              seven requirements of a happy married life. They exchange a sacred
              vow at the beginning of each step:
            </p>
            <p>
              The first step is a vow to nourish each other. The second step is
              a vow to grow together in strength. The third step is a vow to
              preserve each other's wealth. The fourth step is a vow to share
              each other's joys and sorrows. The fifth step is a vow promising
              to happily care for their family. The sixth step is a vow to be
              together forever. The seventh step is a vow to remain lifelong
              friends.
            </p>
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Sindhoor and Vitti</div>
              <div className="programItemTitleEng">
                Vermilion and Ring Exchange
              </div>
            </div>
            <img
              className="programImg"
              alt="rings"
              src="/img/program/wedding-rings.png"
            />
          </div>
          <div className="programItemDescription">
            Arsham places Sindhoor (vermilion) in the parting of Kshamina's hair
            and welcomes her into his life as his eternal partner. Sindhoor is
            the mark of a married woman in Hinduism. Kshamina now sits on the
            left side of Arsham, which signifies that she will always be close
            to his heart. Kshamina and Arsham exchange vittis (wedding rings).
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Ashirwad</div>
              <div className="programItemTitleEng">Blessings</div>
            </div>
            <img
              className="programImg"
              alt="bless"
              src="/img/program/namaste.png"
            />
          </div>
          <div className="programItemDescription">
            Once the wedding rituals have been completed, the couple touches the
            feet of the grandmothers, asking for their blessings. The couple
            then leaves the Mandap. As they leave, family and friends from both
            sides shower blessings with flower petals and wish Kshamina and
            Arsham good luck, prosperity, and a long life.
          </div>
        </div>
        <div className="programItem">
          <div className="programTitleContainer">
            <div>
              <div className="programItemTitle">Vidaai</div>
              <div className="programItemTitleEng">Departure of the Bride</div>
            </div>
            <img
              className="programImg"
              alt="vidaai"
              src="/img/program/running.png"
            />
          </div>
          <div className="programItemDescription">
            Vidaai marks the departure of Kshamina from her parental house.
          </div>
        </div>
        <div className="programItem">
          <div className="programItemDescription">
            Thanks you all for attending, we'll be entering cocktail hour after
            the ceremony!
          </div>
        </div>
      </div>
    </StyledProgram>
  );
}

const StyledProgram = styled.div`
  background-color: ${lightPurple};
  .container {
    display: flex;
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    padding-bottom: 2em;
  }

  .pageTitle {
    font-family: "Genty";
    color: ${darkPurple};
    text-align: center;
    padding: 1em 1em 0;
    font-size: 35px;
  }

  .programLogo {
    width: 75%;
    margin: 0 auto;
    margin-right: 10px;
  }

  .date {
    text-align: center;
    font-size: 40px;
    font-family: "Saturday Night";
  }

  .mainTitle {
    text-align: center;
  }

  .programItem {
    padding: 1em 1em 2em;

    .programTitleContainer {
      display: grid;
      grid-template-columns: 82% 18%;
      grid-column-gap: 10px;
      min-height: 60px;
      align-items: center;

      .programImg {
        width: auto;
        max-height: 60px;
        justify-self: center;
      }
    }

    .programItemTitle {
      font-family: "Saturday Night";
      font-size: 40px;
    }
    .programItemTitleEng {
      font-family: "Glacial Indifference";
      font-size: 20px;
      font-weight: 700;
      margin-left: 20px;
      padding-bottom: 10px;
    }

    .programItemDescription {
      font-family: "Glacial Indifference";
      font-size: 20px;
      p {
        padding-bottom: 10px;
      }

      ul {
        padding-top: 10px;
      }
    }
  }
`;
