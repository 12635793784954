import { styled } from "styled-components";
import { darkPurple, lightPurple, medPurple } from "../constants/colors";
import TextsmsIcon from "@mui/icons-material/Textsms";
import EmailIcon from "@mui/icons-material/Email";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";

export function ContactUs() {
  return (
    <StyledContactUs id="contact-us" className="pageSetup">
      <div className="pageTitle">Contact Us</div>
      <div className="body">
        If you have any questions, concerns or you just want to say hi, please
        contact us!
        <div className="contactGrid">
          <div className="contactItem">
            <div>
              <TextsmsIcon fontSize="large" />
            </div>
            <div>Text Kshamina at</div>
            <a href="tel:+16136123787">1-613-612-3787</a>
          </div>
          <div className="contactItem">
            <div>
              <EmailIcon fontSize="large" />
            </div>
            <div>Email us at</div>
            <a href="mailto:arkshamina@gmail.com">arkshamina@gmail.com</a>
          </div>
          <div className="contactItem">
            <div>
              <TextsmsIcon fontSize="large" />
            </div>
            <div>Text Arsham at</div>
            <a href="tel:+16478602295">1-647-860-2295</a>
          </div>
        </div>
      </div>
    </StyledContactUs>
  );
}

const StyledContactUs = styled.div`
  width: 80%;

  .body {
    font-family: Saturday Night;
    font-size: 4em;
  }

  .contactGrid {
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
    padding-top: 1em;
    font-size: 0.8em;
    grid-column-gap: 0.5em;
  }

  .contactItem {
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    width: 7em;
    height: 4em;
    box-shadow: 0 0 0 2px ${medPurple}, -10px 10px 0px 0px ${medPurple};
    padding-top: 0.5em;
  }

  .contactItem:hover {
    transition: background-color 0.8s;
    background-color: ${darkPurple};
    color: ${lightPurple};

    transform: rotate(8deg);
    transition: rotate 5s linear;

    a {
      color: ${lightPurple};
    }
  }

  a {
    font-family: "Monaco";
    color: ${medPurple};
    font-size: 0.5em;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    .body {
      font-size: 2.5em;
    }

    .contactGrid {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      grid-row-gap: 1em;
      justify-content: center;
    }

    .contactItem {
      width: 8em;
      height: 4.2em;
    }
  }
`;
