import { styled } from "styled-components";
import { HenleyRoom } from "../components/schedule/HenleyRoom";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";

export function GettingThere() {
  return (
    <StyledGettingThere id="getting-there">
      <div className="pageTitle">
        Getting There |
        <div className="gettingThere">
          <div className="gettingThereDate">08 • 18 • 2024</div>
        </div>
      </div>
      <HenleyRoom />
    </StyledGettingThere>
  );
}

const StyledGettingThere = styled.div`
  width: 80%;
  margin: 0 auto;

  .pageTitle {
    display: flex;

    .gettingThere {
      display: flex;
      align-items: center;

      .gettingThereDate {
        font-size: 0.7em;
        padding-left: 20px;
      }
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100% !important;

    .pageTitle {
      margin-left: 1em;
      .gettingThere {
        .gettingThereDate {
          padding-left: 10px;
        }
      }
    }
  }
`;
