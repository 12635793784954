import { styled } from "styled-components";
import { darkPurple } from "../constants/colors";

export function Loading() {
  return (
    <StyledLoading id="rsvp">
      <div className="loader"></div>
    </StyledLoading>
  );
}

const StyledLoading = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  padding-top: 10%;

  .loader {
    height: 30px;
    aspect-ratio: 6;
    --c: #0000 64%, ${darkPurple} 66% 98%, #0000 101%;
    background: radial-gradient(35% 146% at 50% 159%, var(--c)) 0 0,
      radial-gradient(35% 146% at 50% -59%, var(--c)) 25% 100%;
    background-size: calc(100% / 3) 50%;
    background-repeat: repeat-x;
    clip-path: inset(0 100% 0 0);
    animation: l2 1s infinite linear;
  }
  @keyframes l2 {
    90%,
    to {
      clip-path: inset(0);
    }
  }
`;
