import { TextField } from "@mui/material";
import { useState } from "react";
import { Guest } from "../../types";
import { EventRsvp } from "./EventRsvp";
import { FormState } from "./RSVPForm";

export function RsvpPerPerson({
  setFormState,
  guestInfo,
  formState,
}: {
  setFormState: ({
    key,
    newValue,
  }: {
    key: keyof FormState;
    newValue: boolean | string;
  }) => void;
  guestInfo: Guest;
  formState: FormState;
}) {
  const [dietary, setDietary] = useState(formState.dietary);

  const handleBlur = () => {
    setFormState({ key: "dietary", newValue: dietary });
  };

  return (
    <>
      <div>
        <EventRsvp
          event={guestInfo.mehendhi}
          eventName={`Will you be attending the mehendi party?`}
          eventKey={"mehendi" as keyof FormState}
          formState={formState}
          setFormState={setFormState}
        />
        <EventRsvp
          event={guestInfo.ceremony}
          eventName={`Will you be attending the ceremony?`}
          eventKey={"ceremony" as keyof FormState}
          formState={formState}
          setFormState={setFormState}
        />
        <EventRsvp
          event={guestInfo.reception}
          eventName={`Will you be attending the reception?`}
          eventKey={"reception" as keyof FormState}
          formState={formState}
          setFormState={setFormState}
        />
      </div>
      <TextField
        className="diet"
        variant="standard"
        label="Do you have any dietary restrictions?"
        value={dietary}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setDietary(event.target.value);
        }}
        onBlur={handleBlur}
      />
    </>
  );
}
