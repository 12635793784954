import PushPinIcon from "@mui/icons-material/PushPin";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import { styled } from "styled-components";
import { darkPurple } from "../../constants/colors";
import { BREAKPOINT_MOBILE } from "../../constants/breakpoints";
import useMediaQuery from "@mui/material/useMediaQuery";

export function HenleyRoom() {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);

  return (
    <StyledHenleyRoom>
      <div className="grid" id="row1Col1">
        <PushPinIcon />
        <a
          href="https://maps.app.goo.gl/pCZhUkymHr62TTjf6"
          target="_blank"
          rel="noreferrer"
        >
          1225 Lake Shore Blvd W, Toronto
        </a>
      </div>

      <div className="grid parking" id="row1Col2">
        <LocalParkingIcon />
        <a
          href="https://maps.app.goo.gl/ecbggxycgrvhtCi97"
          target="_blank"
          rel="noreferrer"
        >
          1 Net Drive
        </a>
      </div>

      <div className="paragraph" id="row2Col1">
        We are getting married at the Henley Room (also known as the Argonaut
        Rowing Club).
      </div>

      <div className="paragraph" id="row2Col2">
        There will be parking passes provided to all guests which will allow you
        to leave your car until 12pm the next day, so you can party the night
        away and stay safe! You can print the{" "}
        <a href="pdf/parking-pass.pdf" target="_blank">
          parking pass
        </a>{" "}
        in advance. There will also be copies available at the venue on the day
        of.
      </div>

      <div id="row3Col1">
        <iframe
          title="The Henley Room"
          width={isMobile ? "300" : "500"}
          height={isMobile ? "180" : "300"}
          loading="lazy"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJj1vGKqU1K4gRePoHM3puhD0&key=AIzaSyAhjqJBFz8Z0pKnMWsc3HPHGq9VKan9nCI&zoom=18&maptype=satellite"
        ></iframe>
      </div>

      <div id="row3Col2">
        <iframe
          title="1 Net Drive"
          width={isMobile ? "300" : "500"}
          height={isMobile ? "180" : "300"}
          loading="lazy"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d942.1155426170528!2d-79.43844183656395!3d43.63324166629476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b35bade4fb4c3%3A0x32f3451d5b4c9285!2s1%20Net%20Dr%2C%20Toronto%2C%20ON%20M6K%203C1!5e0!3m2!1sen!2sca!4v1717260445255!5m2!1sen!2sca"
        ></iframe>
      </div>
    </StyledHenleyRoom>
  );
}

const StyledHenleyRoom = styled.div`
  display: grid;
  width: 95%;
  margin: 0 auto;
  grid-column-gap: 1em;

  grid-template-areas:
    "row1Col1 row1Col2"
    "row2Col1 row2Col2"
    "row3Col1 row3Col2";

  #row1Col1 {
    grid-area: row1Col1;
  }

  #row1Col2 {
    grid-area: row1Col2;
  }

  #row2Col1 {
    grid-area: row2Col1;
  }

  #row2Col2 {
    grid-area: row2Col2;
  }

  #row3Col1 {
    grid-area: row3Col1;
  }

  #row3Col2 {
    grid-area: row3Col2;
  }

  font-family: "Glacial Indifference";
  font-size: 1.2em;
  border-radius: 50px;

  height: max-content;

  a {
    color: ${darkPurple};
  }

  .grid {
    display: grid;
    grid-template-columns: 1.5em 1fr;
    justify-content: center;
    padding: 1em 0;
    font-size: 1.5em;
    align-items: center;
  }

  iframe {
    margin: 2em 0;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    grid-template-areas:
      "row1Col1"
      "row2Col1"
      "row3Col1"
      "row1Col2"
      "row2Col2"
      "row3Col2";

    padding: 0em 2em;
    .grid {
      font-size: 0.9em;
      padding: 5px 0;
    }

    #row1Col2 {
      padding-top: 20px;
    }

    .paragraph {
      font-size: 0.9em;
    }

    iframe {
      margin: 0em;
      margin-top: 10px;
    }
  }
`;
