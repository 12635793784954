import React from "react";
import { styled } from "styled-components";
import Countdown from "react-countdown";
import { darkPurple } from "../constants/colors";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";

export function CountdownTimer() {
  const weddingTime = new Date("August 18, 2024").getTime();
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    return completed ? (
      <div id="countdown" style={{ fontSize: "24px" }}>
        It's time to get married!
      </div>
    ) : (
      <div id="countdown">
        <div className="text">
          {days > 0 ? (
            <>
              <div className="time">{days}</div>
              <div className="daysUntil">days</div>
            </>
          ) : (
            <>
              <div className="time">{hours}</div>
              <div className="name">hours</div>
            </>
          )}
          {/* <div className="time">{hours}</div>
          <div className="time">{minutes}</div>
          <div className="time">{seconds}</div> */}
          {/* <div className="name">hours</div>
          <div className="name">minutes</div>
          <div className="name">seconds</div> */}
        </div>
      </div>
    );
  };
  return (
    <StyledCountdown>
      <Countdown date={weddingTime} renderer={renderer} />
    </StyledCountdown>
  );
}

const StyledCountdown = styled.div`
  #countdown {
    /* font-family: Genty; */
    color: ${darkPurple};
    font-family: "Saturday Night";

    .text {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 0.2em;
      justify-items: center;
      align-items: center;

      .time {
      font-size: 1.5em;
      }

      .daysUntil {
        font-size: 50px;
      }
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    #countdown {
    .text {

      .time {
        font-size: 70px;
      }

      .daysUntil {
        font-size: 40px;
        font-family: "Saturday Night";
      }
    }
  }
`;
