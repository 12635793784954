import { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Menu } from "../components/Menu";
import { BREAKPOINT_MOBILE } from "../constants/breakpoints";
import { darkPurple, lightPurple, medPurple } from "../constants/colors";
import { ContactUs } from "../pages/ContactUs";
import { GettingThere } from "../pages/GettingThere";
import { Home } from "../pages/Home";
import { Home2 } from "../pages/Home2";
import { RSVP } from "../pages/RSVP";
import { Schedule } from "../pages/Schedule";
import { getGuestsRequest } from "../requestUtils/request";
import { Party } from "../types";
import "./App.css";
import { Loading } from "./Loading";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { NoPartyId } from "./NoPartyId";
import { MEHENDI_TIMELINE_STEPS, TIMELINE_STEPS } from "../constants/schedule";
import { FAQ } from "../pages/FAQ";
import { useMediaQuery } from "@mui/material";

function App() {
  const [rsvpInfo, setRsvpInfo] = useState<Party | null>(null);

  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE}px)`);

  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const partyId =
    (parsed?.["partyId"] as string) ?? localStorage.getItem("party-id") ?? null;
  console.log(parsed);

  const [partyIdError, setPartyIdError] = useState("");

  useEffect(() => {
    getGuestsRequest(partyId)
      .then((requestedGuestInfo: any) => {
        console.log("requestedGuestInfo", requestedGuestInfo);
        if (requestedGuestInfo?.guests.length > 0) {
          setRsvpInfo(requestedGuestInfo);
        } else {
          localStorage.removeItem("partyId");
          setPartyIdError("Invalid party ID, please try again.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [partyId]);

  return (
    <StyledApp>
      <Menu />
      <div className={isMobile ? "longScroll" : "scroll"}>
        <section className="page">
          <Home />
        </section>
        <section className="page">
          <Home2 />
        </section>
        <section className="page">
          <GettingThere />
        </section>
        <PasswordProtectedComponents
          partyId={partyId}
          partyIdError={partyIdError}
          rsvpInfo={rsvpInfo ?? undefined}
        />
        <section className={isMobile ? "longPage" : "page"}>
          <FAQ />
        </section>
        <section className={isMobile ? "longPage" : "page"}>
          <ContactUs />
        </section>
      </div>
    </StyledApp>
  );
}

export default App;

export function PasswordProtectedComponents({
  partyId,
  partyIdError,
  rsvpInfo,
}: {
  partyId?: string;
  partyIdError: string;
  rsvpInfo?: Party;
}) {
  if (!partyId || !rsvpInfo) {
    return (
      <section id="rsvp" className="page">
        <NoPartyId partyIdError={partyIdError} />
      </section>
    );
  }
  if (rsvpInfo?.guests.length === 0) {
    return (
      <section className="page">
        <Loading />
      </section>
    );
  }
  return (
    <div>
      {rsvpInfo?.guests?.[0].mehendhi.isInvited && (
        <section className="page">
          <Schedule
            timelineSteps={MEHENDI_TIMELINE_STEPS}
            date="08 • 16 • 2024"
            title="Mehendi Party, Markham"
            subtitle="Graciously hosted by Nileshmama and Luciemami"
          />
        </section>
      )}
      <section className="page">
        <Schedule
          timelineSteps={TIMELINE_STEPS}
          date="08 • 18 • 2024"
          title="Wedding & Reception, Toronto"
        />
      </section>
      <section className="page" id="rsvp">
        <RSVP rsvpInfo={rsvpInfo} partyId={partyId} />
      </section>
    </div>
  );
}

export const StyledApp = styled.div`
  .scroll {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-padding-top: 5vh;
    height: 100vh;
    width: 100%: 
  }
  .page {
    min-height: 100vh;
    scroll-snap-stop: always;
    scroll-snap-align: start;
  }


  .pageSetup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  background-color: ${lightPurple};

  .pageTitle {
    font-family: "Genty";
    font-size: 5em;
    color: ${darkPurple};
  }

  .date {
    font-family: Genty;
    font-size: 45px;
    padding-top: 20px;
    color: ${darkPurple};
  }

  .city {
    font-family: "Saturday Night";
    font-size: 55px;
    color: ${darkPurple};
  }

  .details {
    font-family: "Saturday Night";
    font-size: 35px;
    color: ${medPurple};
    padding-top: 20px;
  }

  img {
    justify-self: center;
  }

  .App-logo {
    height: 30vmin;
    pointer-events: none;
  }

  .App-logo {
    margin: 0 auto;
  }

  .App-header {
    display: flex;
    flex-direction: column;
    font-size: 80px;
    color: ${medPurple};
    font-family: "Saturday Night";
    align-items: center;

    .name1 {
      text-align: left;
      align-self: flex-start;
      padding-left: 1em;
    }

    .name2 {
      text-align: right;
      align-self: flex-end;
      padding-right: 1em;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    .shiftLeft {
      margin-left: 2.5em;
    }

    .scroll {
      scroll-padding-top: 5vh;
    }

    .longScroll {
      display: flex;
      flex-direction: column;
      width: 100%: 
    
  }
      .longPage {
        min-height: 105vh; 
        padding-top: 5em; 
      }

    .pageTitle {
      font-size: 2em;
    }

    .date {
      font-size: 45px;
      padding-top: 20px;
    }

    .city {
      font-size: 55px;
    }

    .details {
      font-size: 35px;
      padding-top: 20px;
    }

    img {
      justify-self: center;
    }

  }
`;
