import { TextField } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useState } from "react";
import { styled } from "styled-components";
import { darkPurple } from "../../constants/colors";
import { FormState } from "./RSVPForm";

export const isMissingGuestName = (guestName?: string) =>
  (guestName ?? "").trim().toLowerCase() === "additional";

export const GuestNameTooltip = ({
  guestName,
  isMobile = false,
}: {
  guestName: string;
  isMobile?: boolean;
}) => (
  <LightTooltip
    enterTouchDelay={isMobile ? 0 : 700}
    title={
      <>
        Please <a href="mailto:arkshamina@gmail.com">email</a> us to add your
        plus one's name!
      </>
    }
  >
    <div className={isMobile ? "guestName" : ""}>{guestName}</div>
  </LightTooltip>
);

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 16,
    border: `1px dashed ${darkPurple}`,
    fontFamily: "Glacial Indifference",
  },
}));

export function GuestNameInput({
  setFormState,
  formState,
  index,
}: {
  setFormState: ({
    index,
    key,
    newValue,
  }: {
    index: number;
    key: keyof FormState;
    newValue: boolean | string;
  }) => void;
  formState: FormState;
  index: number;
}) {
  console.log(formState);
  const [name, setName] = useState(formState.name);

  const handleBlur = () => {
    setFormState({ index, key: "name", newValue: name });
  };

  return (
    <TextField
      className="guestNameInput"
      variant="standard"
      value={name}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
      }}
      onBlur={handleBlur}
    />
  );
}
